<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card
          :title="$t('Create New Owwner')"
        >
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.owner"
                  class="mt-2"
                  :state="errors.owner ?false:null"
                  :placeholder="$t('owner')"
                />
                <small
                  v-if="errors.owner"
                  class="text-danger"
                >{{ errors.owwner[0] }}</small>
                <label>{{ $t('owner') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.phone"
                  class="mt-2"
                  :state="errors.phone ?false:null"
                  :placeholder="$t('Phone')"
                />
                <small
                  v-if="errors.phone"
                  class="text-danger"
                >{{ errors.phone[0] }}</small>
                <label>{{ $t('Phone') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.identify"
                  class="mt-2"
                  :state="errors.identify ?false:null"
                  :placeholder="$t('identifyNo')"
                />
                <small
                  v-if="errors.identify"
                  class="text-danger"
                >{{ errors.identify[0] }}</small>
                <label>{{ $t('identifyNo') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <div>
                <label>{{ $t('Identification_type') }}</label>
                <v-select
                  v-model="data.identification_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="types=>types.key"
                  :options="types"
                />
                <small
                  v-if="errors.identification_type"
                  class="text-danger"
                >{{ errors.identification_type[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <div class="form-label-group">
                <b-form-textarea
                  v-model="data.adress"
                  class="mt-2"
                  :state="errors.adress ?false:null"
                  :placeholder="$t('adress')"
                />
                <small
                  v-if="errors.adress"
                  class="text-danger"
                >{{ errors.adress[0] }}</small>
                <label>{{ $t('adress') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <div class="form-label-group">
                <b-form-textarea
                  v-model="data.currentAdres"
                  class="mt-2"
                  :state="errors.currentAdres ?false:null"
                  :placeholder="$t('currentAdres')"
                />
                <small
                  v-if="errors.currentAdres"
                  class="text-danger"
                >{{ errors.currentAdres[0] }}</small>
                <label>{{ $t('currentAdres') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.placeNo"
                  class="mt-2"
                  :state="errors.placeNo ?false:null"
                  :placeholder="$t('placeNo')"
                />
                <small
                  v-if="errors.placeNo"
                  class="text-danger"
                >{{ errors.placeNo[0] }}</small>
                <label>{{ $t('placeNo') }}</label>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
      >

        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('chassis_numbe') }}</label>
                <b-form-input
                  v-model="data.chassis_number"
                  :formatter="formatter"
                  class="mt-2"
                  :state="errors.chassis_number ?false:null"
                  :placeholder="$t('Chassis_numbe')"
                />
                <small
                  v-if="errors.chassis_number"
                  class="text-danger"
                >{{ errors.chassis_number[0] }}</small>
                <label>{{ $t('Chassis_numbe') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('chassis_number_type') }}</label>
                <b-form-input
                  v-model="data.chassis_number_type"
                  class="mt-2"
                  :state="errors.chassis_number_type ?false:null"
                  :placeholder="$t('chassis_number_type')"
                />
                <small
                  v-if="errors.chassis_number_type"
                  class="text-danger"
                >{{ errors.chassis_number_type[0] }}</small>
                <label>{{ $t('chassis_number_type') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('cabin_reserve_number') }}</label>
                <b-form-input
                  v-model="data.cabin_reserve_number"
                  class="mt-2"
                  :state="errors.cabin_reserve_number ?false:null"
                  :placeholder="$t('cabin_reserve_number')"
                />
                <small
                  v-if="errors.cabin_reserve_number"
                  class="text-danger"
                >{{ errors.cabin_reserve_number[0] }}</small>
                <label>{{ $t('cabin_reserve_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('cabin_reserve_number_type') }}</label>
                <b-form-input
                  v-model="data.cabin_reserve_number_type"
                  class="mt-2"
                  :state="errors.cabin_reserve_number_type ?false:null"
                  :placeholder="$t('cabin_reserve_number_type')"
                />
                <small
                  v-if="errors.cabin_reserve_number_type"
                  class="text-danger"
                >{{ errors.cabin_reserve_number_type[0] }}</small>
                <label>{{ $t('cabin_reserve_number_type') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Created_year') }}</label>
                <cleave
                  v-model="data.created_year"
                  class="form-control"
                  :class="errors.created_year ?'is-invalid':null"
                  :options="{date: true, datePattern: ['Y']}"
                  :placeholder="$t('Created_year')"
                />
                <small
                  v-if="errors.created_year"
                  class="text-danger"
                >{{ errors.created_year[0] }}</small>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('first_registeraion') }}</label>
                <cleave
                  v-model="data.first_registeraion"
                  class="form-control"
                  :class="errors.first_registeraion ?'is-invalid':null"
                  :options="{date: true, datePattern: ['Y']}"
                  :placeholder="$t('first_registeraion')"
                />
                <small
                  v-if="errors.first_registeraion"
                  class="text-danger"
                >{{ errors.first_registeraion[0] }}</small>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('cabin_number') }}</label>
                <b-form-input
                  v-model="data.cabin_number"
                  class="mt-2"
                  :state="errors.cabin_number ?false:null"
                  :placeholder="$t('cabin_number')"
                />
                <small
                  v-if="errors.cabin_number"
                  class="text-danger"
                >{{ errors.cabin_number[0] }}</small>
                <label>{{ $t('cabin_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('cabin_number_type') }}</label>
                <b-form-input
                  v-model="data.cabin_number_type"
                  class="mt-2"
                  :state="errors.cabin_number_type ?false:null"
                  :placeholder="$t('cabin_number_type')"
                />
                <small
                  v-if="errors.cabin_number_type"
                  class="text-danger"
                >{{ errors.cabin_number_type[0] }}</small>
                <label>{{ $t('cabin_number_type') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_number') }}</label>
                <b-form-input
                  v-model="data.motor_number"
                  class="mt-2"
                  :state="errors.motor_number ?false:null"
                  :placeholder="$t('motor_number')"
                />
                <small
                  v-if="errors.motor_number"
                  class="text-danger"
                >{{ errors.motor_number[0] }}</small>
                <label>{{ $t('motor_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_number_type') }}</label>
                <b-form-input
                  v-model="data.motor_number_type"
                  class="mt-2"
                  :state="errors.motor_number_type ?false:null"
                  :placeholder="$t('motor_number_type')"
                />
                <small
                  v-if="errors.motor_number_type"
                  class="text-danger"
                >{{ errors.motor_number_type[0] }}</small>
                <label>{{ $t('motor_number_type') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_reserve_number') }}</label>
                <b-form-input
                  v-model="data.motor_reserve_number"
                  class="mt-2"
                  :state="errors.motor_reserve_number ?false:null"
                  :placeholder="$t('motor_reserve_number')"
                />
                <small
                  v-if="errors.motor_reserve_number"
                  class="text-danger"
                >{{ errors.motor_reserve_number[0] }}</small>
                <label>{{ $t('motor_reserve_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_reserve_number_type') }}</label>
                <b-form-input
                  v-model="data.motor_reserve_number_type"
                  class="mt-2"
                  :state="errors.motor_reserve_number_type ?false:null"
                  :placeholder="$t('motor_reserve_number_type')"
                />
                <small
                  v-if="errors.motor_reserve_number_type"
                  class="text-danger"
                >{{ errors.motor_reserve_number_type[0] }}</small>
                <label>{{ $t('motor_reserve_number_type') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_manufacturer') }}</label>
                <b-form-input
                  v-model="data.motor_manufacturer"
                  class="mt-2"
                  :state="errors.motor_manufacturer ?false:null"
                  :placeholder="$t('motor_manufacturer')"
                />
                <small
                  v-if="errors.motor_manufacturer"
                  class="text-danger"
                >{{ errors.motor_manufacturer[0] }}</small>
                <label>{{ $t('motor_manufacturer') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_model') }}</label>
                <b-form-input
                  v-model="data.motor_model"
                  class="mt-2"
                  :state="errors.motor_model ?false:null"
                  :placeholder="$t('motor_model')"
                />
                <small
                  v-if="errors.motor_model"
                  class="text-danger"
                >{{ errors.motor_model[0] }}</small>
                <label>{{ $t('motor_model') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('color') }}</label>
                <b-form-input
                  v-model="data.color"
                  class="mt-2"
                  :state="errors.color ?false:null"
                  :placeholder="$t('color')"
                />
                <small
                  v-if="errors.color"
                  class="text-danger"
                >{{ errors.color[0] }}</small>
                <label>{{ $t('color') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('car_type') }}</label>
                <b-form-select
                  v-model="data.car_type"
                  :options="typeoptions"
                />
                <small
                  v-if="errors.car_type"
                  class="text-danger"
                >{{ errors.car_type[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('fuel_type') }}</label>
                <b-form-select
                  v-model="data.fuel_type"
                  :options="fueltypeoptions"
                />
                <small
                  v-if="errors.fuel_type"
                  class="text-danger"
                >{{ errors.fuel_type[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.other_fee"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('other_fee')"
                  :state="errors.other_fee ?false:null"
                  :class="errors.other_fee ?'is-invalid':null"
                />
                <small
                  v-if="errors.other_fee"
                  class="text-danger"
                >{{ errors.other_fee[0] }}</small>
                <label>{{ $t('other_fee') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Expiration_date') }}</label>
                <b-form-datepicker
                  v-model="data.expiration_date"
                  :placeholder="$t('Expiration_date')"
                  :state="errors.expiration_date ?false:null"
                />
                <small
                  v-if="errors.expiration_date"
                  class="text-danger"
                >{{ errors.expiration_date[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <div class="form-label-group">
                <label>{{ $t('notes') }}</label>
                <b-form-input
                  v-model="data.notes"
                  class="mt-2"
                  :state="errors.notes ?false:null"
                  :placeholder="$t('notes')"
                />
                <small
                  v-if="errors.notes"
                  class="text-danger"
                >{{ errors.notes[0] }}</small>
                <label>{{ $t('notes') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('public/specific') }}</label>
                <b-form-checkbox
                  v-model="data.owner_type"
                  :value="true"
                  :state="errors.owner_type ?false:null"
                >
                  {{ data.owner_type ? $t('specific') : $t('public') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.owner_type"
                class="text-danger"
              >{{ errors.owner_type[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('brake') }}</label>
                <b-form-checkbox
                  v-model="data.brake"
                  :value="true"
                  :state="errors.brake ?false:null"
                >
                  {{ data.brake ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.brake"
                class="text-danger"
              >{{ errors.brake[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('light') }}</label>
                <b-form-checkbox
                  v-model="data.light"
                  :value="true"
                  :state="errors.light ?false:null"
                >
                  {{ data.light ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.light"
                class="text-danger"
              >{{ errors.light[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('combustion') }}</label>
                <b-form-checkbox
                  v-model="data.combustion"
                  :value="true"
                  :state="errors.combustion ?false:null"
                >
                  {{ data.combustion ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.combustion"
                class="text-danger"
              >{{ errors.combustion[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('wheel') }}</label>
                <b-form-checkbox
                  v-model="data.wheel"
                  :value="true"
                  :state="errors.wheel ?false:null"
                >
                  {{ data.wheel ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.wheel"
                class="text-danger"
              >{{ errors.wheel[0] }}</small>
            </b-col>
          </b-row>
        </b-card>

      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>

          <b-col
            cols="12"
          >
            <b-card :title="$t('Carrs')">
              <label>{{ $t('please select...') }}</label>
              <template #option="{ manufacturer,model,car_type }">
                <span> {{ manufacturer }} ({{ model }} -- {{ $t(car_type) }})</span>
              </template>
              <v-select
                v-model="data.carr"
                :options="Carrs"
                :reduce="Carrs => Carrs.id"
                label="name"
              >
                <template #option="{ manufacturer,model,car_type }">
                  <span> {{ manufacturer }} ({{ model }} -- {{ $t(car_type) }})</span>
                </template>
              </v-select>
              <small
                v-if="errors.carr"
                class="text-danger"
              >{{ errors.carr[0] }}</small>
            </b-card>
          </b-col>

          <b-col
            cols="12"
          >
            <b-card :title="$t('Platees')">
              <b-col
                cols="12"
                md="12"
              >
                <div class="form-label-group">
                  <cleave
                    v-model="data.number"
                    class="form-control mt-2"
                    :raw="false"
                    :options="{
                      numeral: true,
                      numeralThousandsGroupStyle: 'none',
                      numeralDecimalScale: 0,
                    }"
                    :placeholder="$t('NumberD')"
                    :state="errors.number ?false:null"
                    :class="errors.number ?'is-invalid':null"
                  />
                  <small
                    v-if="errors.number"
                    class="text-danger"
                  >{{ errors.number[0] }}</small>
                  <label>{{ $t('start') }}</label>
                </div>
              </b-col>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="create"
                  >
                    {{ $t('Create') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="resetData()"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-col
          cols="12"
        >
          <b-card :title="$t('Carrs')">
            <b-row
              v-if="data.carr"
            >
              <b-col
                v-for="el in ['no','manufacturer', 'model','passenger', 'axle', 'cylinder','front_overhang','rear_overhang','axle_hole','length','width','wight', 'motor_power', 'motor_size', 'car_type']"
                :key="el"
                cols="2"
              >
                <b-form-group>
                  {{ $t(el) }}
                  <b-form-input
                    plaintext
                    :value="$t(SelectedCar(data.carr)[el])"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div
              v-else
              class="text-center"
            >
              البيانات غير متاحة
            </div>
          </b-card>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        { value: 'Container', text: 'كونتينر' },
        { value: 'ROR', text: 'رورو' },
        { value: 'Land_shipping', text: 'شحن بري' },
        { value: 'Other', text: 'غير ذلك' },
      ],
      typeoptions: [
        { value: 'Normal', text: 'عادي' },
        { value: 'Assa', text: 'قصة' },
      ],
      fueltypeoptions: [
        { value: 'mazot', text: 'مازوت' },
        { value: 'benzin', text: 'بنزين' },
        { value: 'gaz', text: 'غاز' },
      ],
      data: {
        created_year: '',
        car: null,
        platee: null,
        owner: null,
        phone: null,
        identify: null,
        Identification_type: null,
        adress: null,
        currentAdres: null,
        placeNo: null,
        number: null,
        check_in_date: `${this.moment().format('YYYY-MM-DD')}`,
        owner_type: true,
        brake: true,
        light: true,
        combustion: true,
        fee: 0,
        first_fee: 0,
        yearly_fee: 0,
        other_fee: 0,
        total: 0,
        wheel: true,
      },
      datanew: false,
      created_year: {
        N: '1992',
        P: '1993',
        R: '1994',
        S: '1995',
        T: '1996',
        V: '1997',
        W: '1998',
        X: '1999',
        Y: '2000',
        1: '2001',
        2: '2002',
        3: '2003',
        4: '2004',
        5: '2005',
        6: '2006',
        7: '2007',
        8: '2008',
        9: '2009',
        A: '2010',
        B: '2011',
        C: '2012',
        D: '2013',
        E: '2014',
        F: '2015',
        G: '2016',
        H: '2017',
        J: '2018',
        K: '2019',
        L: '2020',
        M: '2021',
      },
      errors: [],
      fileErrors: '',

    }
  },
  computed: {
    types() {
      return [{ key: 'identify', title: this.$t('identifyType') }, { key: 'passport', title: this.$t('passport') }, { key: 'other', title: this.$t('other') }]
    },
    Carrs() { return this.$store.getters['carr/GetElements'] },
  },
  watch: {
    'data.color': function () {
      this.data.color1 = this.data.color
      this.data.color2 = this.data.color.substring(this.data.color1.split(' ')[0].length)
    },
    // eslint-disable-next-line func-names
    'data.chassis_number': function (newEl) {
      const year = this.created_year[newEl.slice(10, 11)]
      if (year) {
        this.data.created_year = year
      }
    },
  },
  created() {
    this.data.expiration_date = this.moment().add(12, 'months').format('YYYY-MM-DD')

    this.$store.dispatch('carr/GetElements')
  },
  methods: {
    formatter(value) {
      return value.toUpperCase()
    },
    clearData() {
      this.data = {
        fee: 0,
        other_fee: 0,
        yearly_fee: 0,
        first_fee: 0,
        total: 0,
        datanew: true,
        owner_type: true,
        brake: true,
        platee: null,
        light: true,
        combustion: true,
        wheel: true,
        expiration_date: this.moment().add(3, 'months').format('YYYY-MM-DD'),
      }
    },
    SelectedCar(val) {
      return this.Carrs.find(el => el.id === val)
    },
    resetData() {
      this.data = {
        fee: 0,
        other_fee: 0,
        yearly_fee: 0,
        first_fee: 0,
        total: 0,
        datanew: true,
        owner_type: true,
        brake: true,

        light: true,
        combustion: true,
        wheel: true,
        platee: null,
        car: null,
        expiration_date: this.moment().add(3, 'months').format('YYYY-MM-DD'),
      }
      this.errors = []
    },
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    ExpirationDateAfter(months) {
      this.data.expiration_date = this.moment().add(months, 'months').format('YYYY-MM-DD')
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.data.fee = 0
      this.errors = []
      this.$store.dispatch('old/Create', this.data).then({
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
